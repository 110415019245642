import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { FlashMessage, FlashMessagesService, ModalRef, ModalService } from '@dougs/ds';
import { CheckoutOptions } from '@dougs/services/dto';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { UserTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class ActivateComptastartModalService {
  constructor(
    private readonly modalRef: ModalRef,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly userStateService: UserStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly metricsService: MetricsService,
  ) {}

  public companyHasCard$: Observable<boolean> = this.companyStateService.activeCompany$.pipe(
    map((activeCompany) => !!activeCompany?.subscription?.hasCard),
  );

  async activateSubscriptionComptastart(): Promise<void> {
    const hasCard = await lastValueFrom(this.companyHasCard$.pipe(first()));
    let token: string | null | undefined = null;
    if (!hasCard) {
      token = await this.openCheckoutModal(this.companyStateService.activeCompany, {
        title: 'Ajouter ma carte',
        buttonText: 'Valider ma carte',
        loadingText: 'Ajout de la carte...',
      });

      if (!token) {
        return;
      }
      this.metricsService.pushMixpanelEvent('Accounting Checkout Bank Card Clicked');
    }

    const flashMessage: FlashMessage = this.flashMessagesService.show(
      `Nous validons votre carte, cela peut prendre quelques secondes`,
      {
        type: 'pending',
        timeout: 0,
      },
    );

    const selectedPlan: SubscriptionPlan = { id: 'comptastart' } as SubscriptionPlan;
    const isActivated: boolean = await this.subscriptionStateService.activate(
      selectedPlan,
      this.companyStateService.activeCompany,
      token,
    );
    this.flashMessagesService.close(flashMessage);

    if (!isActivated) {
      return;
    }

    this.metricsService.pushGAEvent('plan-chosen');
    this.modalRef.close(true);

    await this.userStateService.refreshActiveUser(this.userStateService.activeUser);
    await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
    await this.companyStateService.refreshCompany();
    this.subscriptionStateService.openAcceptSubscriptionPlanPoliciesModal.next();
  }

  async openCheckoutModal(company: Company, options: CheckoutOptions): Promise<string | null | undefined> {
    const { CheckoutModalComponent } = await import('../checkout-modal/checkout-modal.component');
    return (
      await lastValueFrom(
        this.modalService
          .open<string>(CheckoutModalComponent, {
            data: {
              company,
              options,
            },
          })
          .afterClosed$.pipe(take(1)),
      )
    )?.data;
  }
}

<div dougsModalTitle>Récapitulatif de votre offre Comptastart</div>
<div dougsModalContent>
  <h3>
    Vous avez créé votre entreprise : félicitations !
    <img src="images/emoji/party-popper.png" />
  </h3>
  <p class="my-16">Plus que 2 étapes pour pouvoir gérer votre comptabilité l'esprit tranquille :</p>
  <div class="timeline ml-24 my-16">
    <div class="timeline__item completed pl-24 py-8">Créer votre entreprise</div>
    <div class="timeline__item completed pl-24 py-8">Réceptionner votre Kbis</div>
    <div class="timeline__item completed pl-24 py-8">Ouvrir votre compte bancaire professionnel</div>
    <div class="timeline__item completed pl-24 py-8">Créer votre compte Dougs</div>
    <div
      class="timeline__item pl-24 py-8"
      [ngClass]="{ completed: activateComptastartModalService.companyHasCard$ | async }"
    >
      Renseigner votre carte bancaire
    </div>
    <div class="timeline__item pl-24 py-8">Signer la lettre de mission</div>
    <div class="timeline__item pl-24 py-8">Synchroniser le compte bancaire de votre entreprise</div>
  </div>
  <div class="modal-information py-16 px-24">
    <div class="mb-16">Votre abonnement Comptastart comprend :</div>
    <div>
      <ul>
        <li>Un accès à Dougs, l’appli intelligente qui vous permet de suivre votre comptabilité au quotidien.</li>
        <li>Votre bilan et votre compte de résultat toujours à jour.</li>
        <li>Le suivi et la préparation de vos déclarations de TVA.</li>
        <li>Un accompagnement d’experts et des conseils personnalisés à tout moment.</li>
      </ul>
    </div>
  </div>
</div>

<div dougsModalFooter>
  <dougs-button (click)="activateComptastartModalService.activateSubscriptionComptastart()">
    Activer votre compte
  </dougs-button>
</div>

import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent, ModalContentDirective, ModalFooterDirective, ModalTitleDirective } from '@dougs/ds';
import { ActivateComptastartModalService } from './activate-comptastart-modal.service';

@Component({
  selector: 'dougs-activate-comptastart',
  templateUrl: './activate-comptastart-modal.component.html',
  styleUrls: ['./activate-comptastart-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalTitleDirective, ModalContentDirective, ModalFooterDirective, ButtonComponent, NgClass],
  providers: [ActivateComptastartModalService],
})
export class ActivateComptastartModalComponent {
  constructor(public readonly activateComptastartModalService: ActivateComptastartModalService) {}
}
